import axios from 'axios'

const state = {
  purchasesList: [],
}

const getters = {
  purchasesList: state => state.purchasesList,
}

const actions = {
  async getPurchasesList({ commit }) {
    await axios.get('purchase/purchase_list/').then(response => {
      commit('SET_PURCHASE_LIST', response.data)
    })
  },
}

const mutations = {
  SET_PURCHASE_LIST: (state, data) => {
    state.purchasesList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
