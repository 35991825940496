<template>
  <v-snackbar
    v-model="show"
    transition="slide-x-reverse-transition"
    top
    right
    text
    style="z-index: 1000; top: 0px"
    :color="color"
    :timeout="timeout"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="message" />
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="color"
        icon
        v-bind="attrs"
        @click="show = false"
      >
        <v-icon class="mdil-36px mdil-rotate-45">
          mdil-plus
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
      timeout: -1
    }
  },

  created() {
    this.$store.watch(
      state => state.notification.message,
      () => {
        const msg = this.$store.state.notification.message
        if (msg !== '') {
          this.show = true
          this.message = this.$store.state.notification.message
          this.color = this.$store.state.notification.type
          this.timeout = this.$store.state.notification.timeout
          this.$store.commit('SET_NOTIFICATION', {
            message: '',
            type: '',
            timeout: -1
          })
        }
      }
    )
  }
}
</script>
