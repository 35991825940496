<template>
  <div>
    <div class="text-caption text--secondary">
      {{ $t('Fields marked with * are required.') }}
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>