import axios from 'axios'

const state = {
  expenseTypesList: [],
}

const getters = {
  expenseTypesList: state => state.expenseTypesList,
}

const actions = {
  async getExpenseTypesList({ commit }) {
    await axios.get('expense-type/all/').then(response => {
      commit('SET_EXPENSE_TYPE_LIST', response.data)
    })
  },
}

const mutations = {
  SET_EXPENSE_TYPE_LIST: (state, data) => {
    state.expenseTypesList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
