import axios from 'axios'
// import i18n from '@/plugins/i18n.js'

const state = {
  accessToken: null,
  authUserData: {},
  authUserPermissions: [],
  isLoggedIn: false,
  rtl: true,
  themeIsDark: false,
  locale: 'ckb',
  sectionTitle: {},
  monthsList: [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 11, value: 11 },
    { text: 12, value: 12 },
  ],
  monthPlanList: [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 11, value: 11 },
    { text: 12, value: 12 },
    { text: 13, value: 13 },
    { text: 14, value: 14 },
    { text: 15, value: 15 },
    { text: 16, value: 16 },
    { text: 17, value: 17 },
    { text: 18, value: 18 },
    { text: 19, value: 19 },
    { text: 20, value: 20 },
    { text: 21, value: 21 },
    { text: 22, value: 22 },
    { text: 23, value: 23 },
    { text: 24, value: 24 },
  ]
}

const getters = {
  authUserData: state => state.authUserData,

  authUserPermissions: state => state.authUserPermissions,

  isLoggedIn: state => state.isLoggedIn,

  rtl: state => state.rtl,

  locale: state => state.locale,

  themeIsDark: state => state.themeIsDark,

  sectionTitle: state => state.sectionTitle,

  monthsList: state => state.monthsList,

  monthPlanList: state => state.monthPlanList,
}

const actions = {
  async login({ commit }, payload) {
    await axios.post('login/', payload).then(response => {
      commit('SET_LOGIN', response.data)
    })
  },

  async getAuthUserData({ commit }) {
    await axios.get('user/me/').then(response => {
      commit('SET_AUTH_USER_DATA', response.data)
    })
  },

  async getAuthUserPermission({ commit }) {
    await axios.get('user/auth_user_permissions/').then(response => {
      commit('SET_AUTH_USER_PERMISSION', response.data)
    })
  },

  async logout({ commit },) {
    try {
      await axios.post('logout/').then(() => {
        commit('SET_LOGOUT')
      })
    } catch (error) {
      commit('SET_LOGOUT')
      window.location.reload()
    }
  },

  async setLocale({ commit }, data) {
    commit('SET_LOCALE', data)
  },

  async toggleRtl({ commit }, data) {
    commit('TOGGLE_RTL', data)
  },

  async toggleTheme({ commit }, data) {
    commit('TOGGLE_THEME', data)
  },

  async setSectionTitle({ commit }, data) {
    commit('SET_SECTION_TITLE', data)
  },
}

const mutations = {
  SET_LOGIN: (state, data) => {
    state.accessToken = data.token
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
    state.authUserData = data.user
    state.rtl = true
    state.locale = 'ckb'
    state.isLoggedIn = true
  },

  SET_AUTH_USER_DATA: (state, data) => {
    state.authUserData = data
    state.isLoggedIn = true
  },

  SET_AUTH_USER_PERMISSION: (state, data) => {
    state.authUserPermissions = data
  },

  SET_LOGOUT: (state) => {
    state.accessToken = null
    state.authUserData = {}
    state.isLoggedIn = false
    state.authUserPermissions = []
    localStorage.removeItem('vuex')
    delete axios.defaults.headers.common['Authorization']
  },

  TOGGLE_RTL: (state, data) => {
    state.rtl = data
  },

  TOGGLE_THEME: (state, data) => {
    state.themeIsDark = data
  },

  SET_LOCALE: (state, data) => {
    state.locale = data
  },

  SET_SECTION_TITLE: (state, data) => {
    state.sectionTitle = data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
