import Vue from 'vue'
import i18n from './i18n'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import {
  required,
  digits,
  email,
  max,
  regex,
  between,
  oneOf,
  integer,
  max_value,
  min,
  min_value,
  double,
  required_if,
  numeric
} from 'vee-validate/dist/rules'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(field)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})

extend('required', required)
extend('digits', digits)
extend('email', email)
extend('max', max)
extend('regex', regex)
extend('between', between)
extend('one_of', oneOf)
extend('integer', integer)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('double', double)
extend('required_if', required_if)
extend('numeric', numeric)


Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
