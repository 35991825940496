<template>
  <div>
    <tr>
      <th
        colspan="100"
        class="d-none d-print-table-cell rounded px-2 py-4"
        style="border: 2px solid #000; opacity: 1; color: #000"
      >
        <table>
          <tr>
            <th
              class="font-weight-bold pb-2"
              style="letter-spacing: -0.7px !important"
              :style="`font-size: ${bigSize ? '2.5rem' : '1.9rem'}`"
            >
              پێشانگای مدرید
            </th>
            <th
              class="font-weight-bold pb-2"
              style="letter-spacing: -0.7px !important"
              :style="`font-size: ${bigSize ? '1.3rem' : '1.2rem'}`"
            >
              <span class="pe-2">
                لقی ٤٠ مەتری
              </span>

              هەولێر: ٤٠م / نزیک نەخۆشخانەی ڕزگاری
            </th>
          </tr>

          <tr>
            <th
              colspan="50"
              :style="`font-size: ${bigSize ? '1.3rem' : '1.2rem'}`"
            >
              <div class="d-flex flex-wrap justify-space-around font-weight-bold">
                <div
                  style="width: 100%;"
                  class="d-flex justify-center"
                >
                  <div class="px-2">
                    خاوەنی: دڵشاد عزیز
                  </div>
                  <div class="dir-ltr">
                    0750 445 0524
                  </div>
                </div>

                <div class="d-flex">
                  <div class="px-2">
                    محاسب گیلان
                  </div>
                  <div class="dir-ltr">
                    0750 452 8580
                  </div>
                </div>

                <div class="d-flex">
                  <div class="px-2">
                    احمد
                  </div>
                  <div class="dir-ltr">
                    0750 420 2014
                  </div>
                </div>
              </div>
            </th>
          </tr>

          <tr>
            <th class="text-h6 text-start font-weight-bold">
              <div class="d-inline-block grey lighten-2 px-2 rounded-sm">
                {{ $t('Date') }}: {{ $_format_date(date) }}
              </div>
            </th>
            <th class="text-h6 text-end px-6 font-weight-bold">
              {{ $t('Invoice No.') }}: {{ invoiceNo }}
            </th>
          </tr>
        </table>
      </th>
    </tr>
  </div>
</template>

<script>
export default {
  props: {
    date: { type: String, default: '' },
    invoiceNo: { type: String, default: null },
    bigSize: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
</style>