import axios from 'axios'

const state = {
  inventoriesList: [],
}

const getters = {
  inventoriesList: state => state.inventoriesList,
}

const actions = {
  async getInventoriesList({ commit }) {
    await axios.get('inventory/all/').then(response => {
      commit('SET_INVENTORY_LIST', response.data)
    })
  },
}

const mutations = {
  SET_INVENTORY_LIST: (state, data) => {
    state.inventoriesList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
