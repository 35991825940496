import axios from 'axios'

const state = {
  branchesList: [],
}

const getters = {
  branchesList: state => state.branchesList,
}

const actions = {
  async getBranchesList({ commit }) {
    await axios.get('branch/all/').then(response => {
      commit('SET_BRANCH_LIST', response.data)
    })
  },
}

const mutations = {
  SET_BRANCH_LIST: (state, data) => {
    state.branchesList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
