<template>
  <v-btn
    type="submit"
    class="ms-6"
    color="primary"
    depressed
    small
    :loading="loading"
    @click="submitForm()"
  >
    <v-icon left>
      {{ editMode ? 'mdil-check' : 'mdil-plus' }}
    </v-icon>
    <span class="me-1">
      {{ editMode ? $t('Save') : $t('Add') }}
    </span>
  </v-btn>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false
    }
  },

  created() {
    this.submit = _.debounce(this.submit, 1000)

    // document.querySelector('#app-body').addEventListener('keypress', e => {
    //   if (e.key === 'Enter') {
    //     this.submitForm()
    //   }
    // })
  },

  methods: {
    submit() {
      this.$emit('submitted')
    },

    submitForm() {
      this.loading = true
      this.submit()
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>