import axios from 'axios'

const state = {
  customersList: [],
}

const getters = {
  customersList: state => state.customersList,
}

const actions = {
  async getCustomersList({ commit }) {
    await axios.get('customer/all/').then(response => {
      commit('SET_CUSTOMER_LIST', response.data)
    })
  },
}

const mutations = {
  SET_CUSTOMER_LIST: (state, data) => {
    state.customersList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
