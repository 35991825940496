const state = {
  message: '',
  type: '',
  timeout: 0
}

const actions = {
  setNotification({ commit }, payload) {
    commit('SET_NOTIFICATION', payload)
  }
}

const mutations = {
  SET_NOTIFICATION(state, payload) {
    state.message = payload.message
    state.type = payload.type
    state.timeout = payload.timeout
  }
}

export default {
  state,
  mutations,
  actions
}
