<template>
  <div>
    <v-app-bar
      id="app-bar"
      app
      dense
      flat
      outlined
      :class="$vuetify.theme.dark ? 'dark-top-bar' : 'app-background'"
      style="border-right: none; border-left: none"
    >
      <v-app-bar-nav-icon @click="$emit('toggle-drawer')">
        <template v-slot:default>
          <v-icon>mdil-menu</v-icon>
        </template>
      </v-app-bar-nav-icon>

      <!-- <v-toolbar-items class="mx-2">
        <v-icon>{{ sectionTitle.icon }}</v-icon>
      </v-toolbar-items> -->

      <v-toolbar-title class="font-weight-light">
        {{ $t(sectionTitle.title) }}
      </v-toolbar-title>

      <v-spacer />
      <v-menu
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{ authUserData.username }}
            <v-icon>mdil-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <!-- <v-list-item @click="setLtr('en')">
            <v-list-item-title>
              English
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setRtl()">
            <v-list-item-title>
              کوردی
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="setLtr('tr')">
            <v-list-item-title>
              Turkish
            </v-list-item-title>
          </v-list-item> -->

          <!-- <v-divider /> -->
          <!-- <v-list-item @click="changeTheme()">
            <v-list-item-title>
              {{ $t('Dark Mode') }}
            </v-list-item-title>
          </v-list-item> -->

          <v-divider />
          <v-list-item @click="logoutUser()">
            <v-list-item-title>
              {{ $t('Logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'sectionTitle',
      'authUserData',
    ])
  },

  mounted() {
    window.addEventListener("scroll", () => {
      const appBar = document.getElementById("app-bar")
      if (
        document.body.scrollTop > 120 ||
        document.documentElement.scrollTop > 120
      ) {
        appBar.classList.add("bar-box-shadow")
      } else {
        appBar.classList.remove("bar-box-shadow")
      }
    });
  },

  methods: {
    ...mapActions([
      'toggleRtl',
      'toggleTheme',
      'setLocale',
      'logout'
    ]),

    logoutUser() {
      this.logout().then(() => {
        this.$router.push('/')
      })
    },

    setRtl() {
      this.$vuetify.rtl = true
      this.$i18n.locale = 'ckb'
      this.toggleRtl(true)
      this.setLocale('ckb')
    },

    setLtr(locale) {
      this.$vuetify.rtl = false
      this.$i18n.locale = locale
      this.toggleRtl(false)
      this.setLocale(locale)
    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.toggleTheme(this.$vuetify.theme.dark)
    }
  },
}
</script>

<style lang="scss" scoped>
.dark-top-bar {
  background-color: #121212 !important;
}
</style>