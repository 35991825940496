<template>
  <validation-provider
    v-show="authUserBranchesList.length > 1"
    v-slot="{ errors }"
    :name="$t('Branch')"
    rules="required"
  >
    <v-autocomplete
      v-model="branchId"
      :label="`* ${$t('Branch')}`"
      :items="authUserBranchesList"
      :item-text="item => item.name"
      :item-value="item => item.id"
      outlined
      dense
      :hide-details="hideDetails"
      :class="classes"
      :disabled="disabled"
      :color="$_input_color()"
      :error-messages="errors"
      @input="branchSelected(branchId)"
    />
  </validation-provider>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: { type: [Number, String], default: null },
    classes: { type: String, default: null },
    hideDetails: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters([
      'authUserData',
      'branchesList',
    ]),

    authUserBranchesList() {
      return this.branchesList.filter(branch => this.authUserData.branches.includes(branch.id))
    },

    branchId: {
      get() {
        let branch = this.value ? this.value : this.authUserData.default_branch

        if (!this.value) {
          this.$emit('input', branch)
        }

        this.branchSelected(branch)

        return branch
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  mounted() {
    this.getBranchesList()
  },

  methods: {
    ...mapActions(['getBranchesList']),

    branchSelected(branchId) {
      const foundBranch = this.authUserBranchesList.find(branch => branch.id == branchId)

      if (foundBranch) {
        this.$emit('branch-selected', { customers: foundBranch.customers, inventories: foundBranch.inventories })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>