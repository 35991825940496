<template>
  <div>
    <v-dialog
      v-model="confirmDialog"
      persistent
      transition="slide-y-transition"
      max-width="400"
    >
      <v-card
        v-if="propObject"
        class="dialog-card-border"
      >
        <v-card-title
          class="text-h5 font-weight-light"
          :class="propObject.titleColor"
        >
          {{ $t(propObject.title) }}
        </v-card-title>

        <v-card-text>
          {{ $t(propObject.message) }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            class="me-3"
            @click="$emit('close', false)"
          >
            {{ $t(propObject.cancelBtnText) }}
          </v-btn>

          <v-btn
            :color="propObject.confirmBtnColor"
            depressed
            small
            @click="$emit('close', true)"
          >
            <v-icon
              v-if="propObject.confirmBtnIcon.length > 0"
              small
              left
              class="fs-20"
            >
              {{ propObject.confirmBtnIcon }}
            </v-icon>

            <span>
              {{ $t(propObject.confirmBtnText) }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Confirmation',

  props: {
    confirmDialog: { type: Boolean, default: true },
    propObject: {
      type: Object, default: () => { }
    }
  },
}
</script>

<style lang="scss" scoped></style>
