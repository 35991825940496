import axios from 'axios'

const state = {
  suppliersList: [],
}

const getters = {
  suppliersList: state => state.suppliersList,
}

const actions = {
  async getSuppliersList({ commit }) {
    await axios.get('supplier/all/').then(response => {
      commit('SET_SUPPLIER_LIST', response.data)
    })
  },
}

const mutations = {
  SET_SUPPLIER_LIST: (state, data) => {
    state.suppliersList = data
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
