<template>
  <div>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateValue"
          :label="label"
          readonly
          :disabled="disabled"
          :single-line="singleLine"
          prepend-inner-icon="mdil-calendar"
          dense
          outlined
          :class="classes"
          :clearable="clearable"
          :hide-details="hideDetails"
          :error-messages="errorMessages"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dateValue"
        :no-title="noTitle"
        @input="dateMenu = false"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: '' },
    classes: { type: String, default: '' },
    hideDetails: { type: Boolean, default: false },
    noTitle: { type: Boolean, default: false },
    failed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    errorMessages: { type: Array, default: () => [] },
  },

  data() {
    return {
      dateMenu: false,
    }
  },

  computed: {
    dateValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>