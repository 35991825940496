import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import './plugins/veeValidate'
import './plugins/axios'
import './plugins/components'
import helpers from './mixins/helpers.js'

Vue.config.productionTip = false

Vue.mixin(helpers)

Vue.use(require('vue-moment'))

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
