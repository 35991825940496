<template>
  <div
    :style="`background-color: ${color.includes('#') ? color : '#' + color}; height: ${size}px; width: ${size}px; `"
    style="border-radius: 6px"
    class="me-2"
  />
</template>

<script>
export default {
  props: {
    color: { type: String, default: '' },
    size: { type: [String, Number], default: '16' },
  },
}
</script>

<style lang="scss" scoped>
</style>