import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import auth from './modules/auth'
import notification from './modules/notification'
import currency from './modules/currency'
import supplier from './modules/supplier'
import customer from './modules/customer'
import expense_type from './modules/expense_type'
import purchase from './modules/purchase'
import branch from './modules/branch'
import inventory from './modules/inventory'

export default new Vuex.Store({
  modules: {
    auth,
    notification,
    currency,
    supplier,
    customer,
    expense_type,
    purchase,
    branch,
    inventory,
  },

  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ]
})
